<template>
  <div>
    <select  id="sel1" v-model="dayCount" autocomplete="device kind" class="form-control ml-1">
      <option value="1">1日</option>
      <option value="7">7日</option>
      <option value="30">30日</option>
      <option value="90">90日</option>
    </select>

    <input type="date" v-model="fromDate">

    <ox-charts :patient-id="patientId" :day-count="dayCount" :from-date="fromDate" :series="series"></ox-charts>
  </div>
</template>

<script>

import OxCharts from "@/component/parts/charts/OxCharts";
import moment from "moment";
export default {
  name: "OxChartPage",
  components: {OxCharts},
  data:()=> {
      return {
        dayCount:1,
        fromDate:moment("2021-02-19").format("YYYY-MM-DD"),
        patientId:14,
        series:[]
      }
  },
  watch: {
    fromDate: function () {
      this.getData();
    },
    dayCount() {
      this.getData();
    },
  },
  methods:{
    getData(){
      let values = [10,9,5,11,15,16,17,18,19,20];
      let dates = [];
      function random(arr){
        return arr[Math.floor( Math.random() * arr.length )]
      }
      let baseDate = moment(this.fromDate);
      for(let i=0;i<1440 * this.dayCount  ;i++){
        baseDate = baseDate.add(60,'s');
        dates.push({ x: new Date(baseDate.format('YYYY-MM-DD HH:mm:ss')), y: random(values) },)
      }

      this.series = [{
        name: '呼吸数',
        data: dates
      }];
      // console.log("this.series===",JSON.stringify(this.series));
    },
  },
  mounted(){
    this.getData();
  }
};
</script>

